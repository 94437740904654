import React from 'react'
import { PageProps } from 'gatsby'
import queryString from 'query-string'
import { useEffect, useState } from 'react'
import SEO, { summarize } from '~/utils/seo'
import BlogDetail from '~/components/pages/BlogDetail'

export default function BlogPreviewPage({ location }: PageProps) {
    const { contentId, draftKey } = queryString.parse(location.search)

    const [data, setData] = useState<GatsbyTypes.MicrocmsNewsQuery | null>(null)

    // PreviewはCSRで実現
    useEffect(() => {
        fetch(`https://onsen.microcms.io/api/v1/news/${contentId}?draftKey=${draftKey}`, {
            headers: {
                'X-MICROCMS-API-KEY': `${process.env.microCMS_API_KEY}`
            }
        })
            .then(res => res.json())
            .then(data => setData({ microcmsNews: data }))
    }, [])

    if (data === null) {
        return <p>loading...</p>
    }

    return (
        <>
            <SEO
                title={`${data.microcmsNews.title}| ニュース | Onsen*`}
                description={summarize(data.microcmsNews.contents)}
                image={data.microcmsNews.thumbnail.url}
                robots='noindex,nofollow,noarchive'
                type='article'
                url={location.href}
            />
            <BlogDetail data={data} location={location} pageContext={{
                prev: {
                    title: '',
                    newsId: ''
                },
                next: {
                    title: '',
                    newsId: ''
                }
            }} />
        </>
    )
}